export const formatStrings = (e) => {
    for (var k in e) { 
      if (typeof e[k] === 'string') {
        //if (e[k].trim() == '') e[k] = '----'; 
        e[k] = stripHTML(e[k]);  
        //e[k] = e[k].replace(/●/g,'<br />● '); 
        if (e[k].indexOf('http') != -1 && k.toLocaleLowerCase().indexOf('photo') == -1) e[k] = e[k].wrapURLs(true); 
        //if (k.toLocaleLowerCase().indexOf('photo') != -1) e[k] = e[k] + '&date=' + (new Date()).getTime();
          
        e[k] = bold(e[k]); 
      }
      else if (typeof e[k] === 'object') {
        formatStrings(e[k]);
      }
    }
  }

  const bold = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
  }
  
  const stripHTML = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    return text.replace(/\n/g, "<br />");
  }
  
  export const formatToEdit = (e) => {
    for (var k in e) { 
      if (typeof e[k] === 'string') {
        e[k] = replaceLinkBreaks(e[k]);     
      }
      else if (typeof e[k] === 'object') {
        formatToEdit(e[k]);
      }
    }
  }
  
  const replaceLinkBreaks = (text) => {
    return text.replace(/<br\s*[\/]?>/gi, "\n");
  }
  
  export const convertGreek = (text) => {
    return text.replace(/α/gi, "A").replace(/β/gi, "B");
  }
  
  export const arrayToString = (arr) => {
    if (!(arr instanceof Array)) return arr;
    var str = '';
    arr.forEach(elm => {
      if (elm['field']) str += elm['field'] + '\n';
    });
    return str;
  }