import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormatFileSizePipe } from './format-file-size.pipe';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  // File upload task 
  private fileUploadTask: AngularFireUploadTask;

  // Upload progress
  percentageVal: Observable<number>;

  // Track file uploading with snapshot
  trackSnapshot: Observable<any>;

  // Uploaded File URL
  private UploadedImageURL: Observable<string>;

  // Uploaded image collection
  private files: Observable<imageFile[]>;

  // Image specifications
  private imgName: string;
  private imgSize: number;

  // File uploading status
  private isFileUploading: boolean;
  private isFileUploaded: boolean;

  private filesCollection: AngularFirestoreCollection<imageFile>;

  constructor(
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage
  ) {
    this.isFileUploading = false;
    this.isFileUploaded = false;

    // Define uploaded files collection
    this.filesCollection = afs.collection<imageFile>('test');
    this.files = this.filesCollection.valueChanges();
  }

  uploadImage(event: FileList, path) {

    const file = event.item(0)

    // Image validation
    if (file.type.split('/')[0] !== 'image') {
      console.log('File type is not supported!')
      return;
    }

    this.isFileUploading = true;
    this.isFileUploaded = false;

    this.imgName = file.name;

    // Storage path
    const fileStoragePath = path; //`filesStorage/${new Date().getTime()}_${file.name}`;

    // Image reference
    const imageRef = this.afStorage.ref(fileStoragePath);

    // File upload task
    this.fileUploadTask = this.afStorage.upload(fileStoragePath, file);

    // Show uploading progress
    this.percentageVal = this.fileUploadTask.percentageChanges();
    this.trackSnapshot = this.fileUploadTask.snapshotChanges().pipe(

      finalize(() => {
        // Retreive uploaded image storage path
        this.UploadedImageURL = imageRef.getDownloadURL();  console.log(this.UploadedImageURL);

        this.UploadedImageURL.subscribe(resp => {
          this.storeFilesFirebase({
            name: file.name,
            filepath: resp,
            size: this.imgSize
          });
          this.isFileUploading = false;
          this.isFileUploaded = true;
        }, error => {
          console.log(error);
        })
      }),
      tap(snap => {
        this.imgSize = snap.totalBytes;
      })
    )
  }

  private storeFilesFirebase(image: imageFile) {
    const fileId = this.afs.createId();

    this.filesCollection.doc(fileId).set(image).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    });
  }

  deleteImage(path) {

    const imageRef = this.afStorage.ref(path);
    imageRef.delete().subscribe()
  }

}

export interface imageFile {
  name: string;
  filepath: string;
  size: number;
}